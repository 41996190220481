/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

html, body {
  height: 100%;
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
}

body {
  background-color: black;
}

.header {
  height: 100px;
  background-color: rgb(26, 24, 24);
  color: white;
  display: flex;
  justify-content: end;
  align-items: end;
  padding-right: 15px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.styleBtns {
  font-size: 16px;
  border: 1px solid white;
  border-radius: 3px;
  background-color: gold;
  margin: 0px 5px;
  font-weight: bold;
  color: black !important;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
}

.viewPort {
  width: 100vw;
  height: 100vh;
}

.backdrop {
  background-color: rgb(26, 24, 24);
}

.textWhite {
  color: white;
}

.textGold {
  color: gold;
  font-weight: bold;
}

.textGreen {
  color: rgb(36, 196, 36);
}

.textRed {
  color: red;
}

.faintText {
  color: gray;
}

.mutedText{
  color:rgb(61, 57, 57);
}

.titleDollar {
  font-size: 55px;
  color: gold
}

.font12 {
  font-size: 12px;
}

.font16 {
  font-size: 16px;
}

.font20 {
  font-size: 20px !important;
}

.noStyle {
  text-decoration: none;
}

.marginBelow {
  margin-bottom: 15px;
}

.centerText {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 200px;
}

.displayNONE {
  display: none;
}

.vHidden {
  display: none;
  visibility: hidden;
}

.visibleNow {
  display: block;
  visibility: visible;
}

.arrowSize {
  font-size: 40px;
  color: gold;
  text-decoration: none;
}

.chooseOpponentText {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 22px;
  color: white;
}

.challengeAgainstText {
  font-size: 22px;
  margin-bottom: 20px;
  color: gold;
}

.dropdownElement {
  background-color: black;
}

.marginAgainstForm {
  margin-bottom: 20px;
}

.newSpreadOddsTitle {
  display: flex;
  color: gold;
  font-size: 18px;
}

.searchBoxList {
  width: 100%;
}

.searchBoxListElements {
  background-color: rgb(37, 34, 34);
  padding: 5px 0px;
  padding-left: 10px;
  border: 1px solid rgb(61, 56, 56);
}

.listBtnElements {
  display: flex;
  justify-content: start;
  color: gold;
  font-size: 16px; 
}

.titleBorder {
  padding: 0px 5px;
  border: 1px solid white;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 5px 2px #fff;
  -moz-box-shadow: 0 0 5px 2px #fff;
  box-shadow: 0 0 5px 2px #fff;
}

.titleLowerBorder {
  padding: 0px 5px;
  border-bottom: 1px solid white;
  box-shadow: 0 4px 2px -2px white;
}

.accountValue {
  width: fit-content;
  color: white;
  font-size: 16px;
  padding: 5px 8px;
  border: 1px solid white;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 5px 2px #fff;
  -moz-box-shadow: 0 0 5px 2px #fff;
  box-shadow: 0 0 5px 2px #fff;
}

.title1 {
  font-size: 75px;
  color: white;
}

.title2 {
  font-size: 75px;
  color: gold;
}

.loginTitle1 {
  font-size: 35px;
  color: white;
}

.loginTitle2 {
  font-size: 35px;
  color: gold;
}

.fitIt {
  width: fit-content;
}

.tag-line {
  color: white;
  margin-top: 10px;
}


.mainSettingsCard {
  -moz-box-shadow: 2px 2px 3px white;
  -webkit-box-shadow: 2px 2px 3px white;
  box-shadow: 1px 1px 3px white;
}


.settingsCard {
  border: 1px solid white;
  padding: 15px;
  -moz-box-shadow: 2px 2px 3px white;
  -webkit-box-shadow: 2px 2px 3px white;
  box-shadow: 1px 1px 3px white;
  background-color: rgb(41, 39, 39);
  border-radius: 5px;
}

.settingsTextStyling {
  padding: 5px;
  border: 1px solid white;
  background-color: black;
  font-weight: bold;
}

.deactivateLink{
  text-decoration: none;
  font-size: 18px;
  color: red;
}

.linkCoinbase {
  text-decoration: none;
  margin: 8px;
  padding: 5px;
  border: 1px solid rgb(150, 37, 150);
  background-color: rgb(150, 46, 150);
  border-radius: 5px;
  color: white;
  font-size: 22px;
}

.searchBarText {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: gold;
  border: 1px solid gold;
  color: rgb(255, 136, 0);
  border-radius: 0.25rem;
  font-weight: bold;
}

.searchBarTextNav {
  display: flex;
  align-items: center;
  font-size: 12px !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: gold;
  border: 1px solid gold;
  color: rgb(255, 136, 0);
  border-radius: 0.25rem;
  font-weight: bold;
}

.searchMargin {
  margin-top: 15px;
}

.goldT {
  color: gold;
}

.grayT {
  color: lightgray;
}

.hoverText {
  cursor: pointer;
}

.searchBar {
  width: 700px !important;
}

.navStyle {
  color: gold;
  text-decoration: none;
  margin: 0px 10px 0px 10px;
  text-decoration: underline;
  font-size: 16px;
}

.navStyleNU {
  color: gold;
  text-decoration: none;
  margin: 0px 10px 0px 10px;
  font-size: 18px;
}

.navStyleSideNav {
  color: gold;
  text-decoration: none;
  margin: 0px;
  text-decoration: underline;
  font-size: 16px;
}

.deleteLink {
  color: red;
  text-decoration: none;
  margin: 0px;
  font-size: 18px;
}

.loginSignUpText {
  color: white;
  text-decoration: none;
  margin-top: -10px;
  text-decoration: none;
  font-size: 16px;
}

.formTitleText {
  color: white;
  font-size: 16px;
}

.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: black;
  box-shadow: 2px 3px 4px white;
}

.main {
  margin-left: 300px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
  background-color: black;
}

.topSearchPage {
  display: flex;
  justify-content: center;
}

.heightmax {
  height: 100%;
}

.noBtnStyle {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.noBtnStyle2 {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: fit-content;
}

.searchText {
  color: white;
  margin-top: 25px;
  margin-bottom: 0px;
  font-size: 25px;
}

.searchedText {
  color: white;
  margin-top: -10px;
  font-size: 14px;
}

.betsTitle {
  color: gold;
}

.tinyBorder {
  margin-top: 10px;
  border-left: 2px solid gold;
  border-top: 2px solid gold;
  border-right: 2px solid gold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: fit-content;
  padding: 0px 5px;
  color: gold;
}

.tinyStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  border: 1px solid white;
  padding: 2px 0px;
}

.parlayFriendDiv {
  margin-bottom: 5px;
  background-color: rgb(37, 34, 34);
}

.parlayFriendBorder {
  border: 1px solid white;
}

.bg-for {
  background-color:darkgreen;
}

.bg-against {
  background-color: darkred;
}

.bg-card {
  background-color: rgb(37, 34, 34);
}

/* .parlayTextGame {
  font-size: 12px;
  color: white;
}

.parlayTextTeam {
  font-size: 12px;
  color: gold;
}

.parlayTextDate {
  font-size: 1px;
  color: white;
} */

.parlayFriendTextGame {
  font-size: 16px;
  color: white;
}

.parlayFriendTextTeam {
  font-size: 14px;
  color: gold;
}

.parlayFriendTextDate {
  font-size: 14px;
  color: white;
}

.resultsText {
  padding-left: 5px;
  font-size: 30px;
  color: gold;
  font-weight: bold;
  margin: 0px;
  align-self: flex-end;
}

.seeTicket {
  color: gold;
}


.legendText {
  color: white;
  font-size: 16px;
  padding-left: 10px;
  align-self: flex-end
}

.propCard {
  width: 300px;
  background-color: rgb(34, 36, 37);
}

.propTextDate {
  font-size: 16px;
  color: gold;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 5px;
}

.propTextTime {
  font-size: 16px;
  color: gold;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  margin-bottom: 5px;
}


.teamDiv {
  margin-bottom: 5px;
  border-bottom: 1px solid gold;
  padding-bottom: 10px;
}

.spreadOut {
  margin: 10px 0px;
}

.hideText {
  visibility: hidden;
  margin: 0px;
}

.propTeamText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.propText {
  font-size: 14px;
  color: white;
  text-align: center;
}

.space {
  margin: 0px 3px;
}

.propTextVS {
  font-size: 13px;
  color: white;
  padding-right: 10px;
  padding-top: 10px;
  margin: 0px;
}

.bgColor1 {
  background-color: black;
}

.modal-content {
  background-color: black !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
  -webkit-box-shadow: 0 0 5px 2px #fff !important;
  -moz-box-shadow: 0 0 5px 2px #fff !important;
  box-shadow: 0 0 5px 2px #fff !important;
}

.modalTitle {
  display: flex;
  justify-content: center;
  padding: 3px !important;
  text-align: center;
}

.modalTime {
  margin: 0px;
  padding: 10px 10px 0px 10px;
}

.centerIt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textSpacer {
  margin: 4px 0px;
  font-size: 16px;
  color: white;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.btnSpacer {
  margin: 4px 0px;
}

.addLinkBtn {
  color: gold;
  text-decoration: none;
  margin: 3px 0px;
  font-size: 18px;
}

.separator {
  border-bottom: 1px solid white;
  display: flex;
  justify-content: center;
  padding-bottom: 2px;
}

.rightBorder {
  border-right: 1px solid white;
}

.topBorder {
  border-top: 1px solid white;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.currentTicket {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 1px white solid;
  text-align: center;
}

.optionBoxRush {
  width: 100px;
  height: 100px;
  background-color: darkgreen;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid white;
  -webkit-box-shadow: 0 0 10px white;
  box-shadow: 0 0 10px white;
}

.optionBoxFriend{
  width: 100px;
  height: 100px;
  background-color: darkred;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid white;
  -webkit-box-shadow: 0 0 10px white;
  box-shadow: 0 0 10px white;
}

.rushOptionText {
  color: white;
  font-size: 16px;
  margin: 4px 0px;
  font-weight: bold;
}

.deleteBtnFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteBtn {
  -webkit-appearance: none;
  background-color: darkred;
  font-size: 16px;
  color: white;
  width: auto;
  height: auto;
}

.addBtn {
  -webkit-appearance: none;
  background-color: green;
  font-size: 16px;
  color: white;
  width: auto;
  height: auto;
  margin-right: 5px;
}

.confirmBtn {
  margin-top: 12px;
  margin-left: 20px;
  background-color: darkgreen;
  color: white;
}

.confirmText1 {
  text-align: start;
  color: white;
  font-size: 16px;
}

.confirmText2 {
  text-align: start;
  color: gold;
  font-size: 16px;
}

.confirmTextFriend1 {
  text-align: center;
  color: white;
  font-size: 16px;
}

.confirmTextFriend2 {
  text-align: center;
  color: gold;
  font-size: 16px;
}

.inputTextSize {
  font-size: 12px;
}

.bookBtn {
  font-size: 16px;
  border: 1px solid white;
  border-radius: 3px;
  background-color: green;
  margin: 15px 5px 0px 5px;
  font-weight: bold;
  color: white;
}

.openNav {
  display: none;
}

.coolBeans {
  box-shadow: 1px 1px 4px gold;
  height: fit-content;
  margin-top: 5px;
  background-color: black;
  color: gold;
  font-size: 16px;
  width: fit-content !important;
}

.mobileSideNav {
  height: 100%;  
  width: 100%;  
  position: fixed; 
  z-index: 10; 
  top: 0; 
  left: 0; 
  background-color: black; 
  transition: .5s ease; 
  overflow-x: hidden; 
  padding: 20px;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  box-shadow: -3px 0px 5px 1px black;
  color: white;
}

.sideNavBorders {
  border-left: 1px solid white;
  border-right: 1px solid white;
  box-shadow: 8px 0 8px -6px white, -8px 0 8px -6px white;
  padding-left: 20px;
  padding-right: 20px
}

.fakeFooterMargin {
  margin-bottom: 50px;
}

.paddingBtm {
  padding-bottom: 20px;
}

.spaceFromLogin {
  margin-left: 5px;
  margin-top: 2px;
}

/* DASHBOARD STYLING */

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.dashboardLink {
  display: flex;
  padding-bottom: 5px;
}

.dashboardWelcome {
  padding: 15px 0px 0px 0px;
}

.dashboardWinPercentage {
  font-size: 16px;
  color: white;
}

.queryRecord {
  width: fit-content;
}

.statisticsText {
  color: white;
  font-size: 22px;
  margin: 10px 0px;
  padding: 0px 10px;
}

.openBetTitle {
  color: white;
  font-size: 22px;
  margin-bottom: 5px;
  height: fit-content;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.betHistoryTitle {
  color: white;
  font-size: 22px;
  margin-bottom: 2px;
  padding-top: 10px;
  height: fit-content;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.losses {
  color: red;
  padding-left: 10px;
}

.winnings {
  color: green;
  padding-left: 10px;
}

.selectTimeText {
  color: white;
  font-size: 22px;
  margin-bottom: 10px;
}

.onLink {
  color: gold;
  font-size: 22px;
  text-decoration: none;
  margin: 0px 10px;
  font-weight: 500;
}

.offLink {
  color: white;
  font-size: 20px;
  text-decoration: underline;
  margin: 0px 10px;
}

.bggray {
  background-color: rgb(27, 23, 23);
}

.scrollDiv {
  padding: 4px;
  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify
}

.paddingForOpenBetDiv {
  padding-top: 15px;
}

.friendNav {
    visibility: visible;
    height: 100%;  
    width: 50%;  
    position: fixed; 
    z-index: 11; 
    top: 0; 
    right: 0; 
    background-color: black; 
    transition: all .5s ease 0s; 
    overflow-x: hidden; 
    padding: 20px;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    box-shadow: -1px 0px 5px 1px white;
    color: white;
}

.friendsText {
  display: flex;
  justify-content: start;
  align-content: end;
  font-size: 26px;
}

.addButtonText {
  font-size: 14px;
  height: fit-content;
  align-self: end;
  background-color: gold;
}

.addButtonTextG {
  font-size: 14px;
  height: fit-content;
  align-self: end;
  background-color: gray;
}

.friendRequestLink {
  padding-right: 10px;
  font-size: 16px;
  color: gold;
  height: fit-content;
  text-decoration: none;
}

.friendListDiv {
  background-color: rgb(39, 36, 36);
  border: 1px solid rgb(61, 56, 56);
  border-radius: 5px;
  padding: 3px;
  padding-bottom: 10px;
}

.friendListTextEmail {
  font-size: 18px;
  color: white;
}

.friendListTextRecord {
  font-size: 14px;
  color: white;
}

.friendListTextSpread {
  font-size: 14px;
  color: white;
}

.friendRequestSentDiv {
  border: 1px solid gray;
  border-radius: 5px;
  width: fit-content;
  background-color: darkgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 18px;
}

.friendRequestFailedDiv {
  border: 1px solid gray;
  border-radius: 5px;
  width: fit-content;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 18px;
}

@media only screen and (max-width: 656px) {
  .mobileNav {
    display: none;
  }

  .sidenav {
    width: 0px;
  }

  .main {
    margin-left: 0px;
    padding: 0px 0px 0px 52px;
  }

  .textSpacer {
    font-size: 12px;
  }

  .topSearchPage {
    justify-content: center;
  }

  .currentTicket {
    font-size: 12px;
  }

  .header {
    display: none;
    height: 0px;
    background-color: rgb(26, 24, 24);
    color: white;
    display: flex;
    justify-content: end;
    align-items: end;
    padding-right: 15px;
  }

  .viewPort {
    width: 100vw;
    height: 100vh;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 100px;
  }

  .loginSignUpText{
    margin: 0px;
  }

  .deleteBtn{
    font-size: 12px;
  }

  .friendNav{
    width: 100%;
  }

  .friendsText {
    font-size: 20px;
  }

  .friendListTextEmail {
    font-size: 14px;
    color: white;
  }
  
  .friendListTextRecord {
    font-size: 12px;
    color: white;
  }
  
  .friendListTextSpread {
    font-size: 12px;
    color: white;
  }

  .parlayFriendTextGame {
    font-size: 14px;
  }
  
  .parlayFriendTextTeam {
    font-size: 12px;
  }
  
  .parlayFriendTextDate {
    font-size: 12px;
  }

  .searchBoxList {
    margin-top: 0px;
  }

  .listBtnElements {
    font-size: 12px;
  }

  .mobileFont {
    font-size: 14px;
    text-align: center;
  }

  .arrowSize{
    font-size: 22px;
  }
}

/* Handling smalle screen formatting for the search bar */
@media only screen and (max-width: 730px){
  .searchBar {
    width: auto !important;
  }

  .searchBoxListElements {
    margin-right: 90px;
  }
  
}

@media only screen and (max-width: 656px){
  .openNav {
    display: block;
    z-index: 99;
    padding-right: 5px;
    position: fixed;
    margin-left: 5px;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
  }
}
  
/* Hover Effects */

.hoverText:hover {
  transform: scale(1.1); /* (150% zoom)*/
}

.navStyleNU:hover,
.navStyle:hover,
.deactivateLink:hover {
  color: white;
}

.navStyleSideNav:hover {
  color: white;
}

.seeTicket:hover{
  color:white;
}

.arrowSize:hover {
  color: white;
}

.deleteLink:hover {
  color:white;
}

.addLinkBtn:hover {
  color: white;
}

.friendRequestLink:hover {
  color: white;
}

.onLink:hover,
.linkCoinbase:hover,
.noStyle:hover {
  color: gold;
}

.offLink:hover {
  color: gold;
}

.friendListDiv:hover {
  background-color: rgb(63, 59, 59);
}

.confirmBtn:hover {
  background-color: darkslategrey;
}

/* Spinner */
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: gold;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
